.letter {
  animation: slideUp 0.3s forwards;
}

.wordEnter {
  opacity: 0;
}

.wordEnterActive {
  opacity: 1;
  transition: opacity 1.5s 0.5s; /* Wait for the letters to appear before fading in */
}

.wordExit {
  opacity: 0;
}

.wordExitActive {
  opacity: 0;
  transition: opacity 0.2s;
}

@keyframes slideUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.wordEnterComplete {
  opacity: 0;
}

.wordEnterCompleteActive {
  opacity: 1;
  transition: opacity 1.5s;
}

.wordExitComplete {
  opacity: 0;
}

.wordExitCompleteActive {
  opacity: 0;
  transition: opacity 0.2s;
}
